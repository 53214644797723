/* width */
::-webkit-scrollbar {
  height: 9px;
  width: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 9px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #06a3da;
  border: #06a3da;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.vendor-image {
  height: 100px;
  object-fit: contain;
  max-width: 99%;
  width: 100%;
  margin: auto;
}

.vendor-image-div {
  padding: 0 10px;
}

.img-cover {
  height: 95vh;
  overflow: hidden;
  object-fit: cover;
}

.owl-carousel .owl-stage {
  position: relative;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-lang {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 2;
  gap: 5px;
}

.selected-lang::before {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  background-image: url("./images/us.svg");
  background-size: 32px 32px;
  border-radius: 5px;
}

.lang-menu ul {
  position: absolute;
  margin: 0;
  padding: 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  display: none;
}

.lang-menu:hover ul {
  display: inline-block;
}

.lang-menu ul li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.lang-menu span {
  text-decoration: none;
}

.lang-menu ul li span {
  width: 100%;
  display: flex;
  padding: 5px 10px;
  gap: 7px;
}

.lang-menu ul li span::before {
  content: "";
  display: inline-block;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  border-radius: 5px;
}

.lang-menu ul li span:hover {
  background-color: #f2f2f2;
}

span[data-lang="en"]::before {
  background-image: url("./images/us.svg");
  background-size: 32px 32px;
}

span[data-lang="az"]::before {
  background-image: url("./images/az.svg");
  background-size: 32px 32px;
}

span[data-lang="ru"]::before {
  background-image: url("./images/ru.svg");
  background-size: 32px 32px;
}

.en::before {
  background-image: url("./images/us.svg");
  background-size: 32px 32px;
}

.az::before {
  background-image: url("./images/az.svg");
  background-size: 32px 32px;
}

.ru::before {
  background-image: url("./images/ru.svg");
  background-size: 32px 32px;
}
